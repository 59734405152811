import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  public supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient(
      environment.supabase.supabaseUrl,
      environment.supabase.supabaseKey,
      {
        autoRefreshToken: true,
        persistSession: true,
      }
    );
  }

  signIn(email: string, password: string) {
    return this.supabase.auth.signIn({
      email,
      password,
    });
  }

  async getProfile() {
    try {
      const email = this.supabase.auth.user().email;
      const { data } = await this.supabase
        .from('users')
        .select('*')
        .eq('email', email);

      return data[0];
    } catch (error) {
      throw error;
    }
  }

  signOut() {
    return this.supabase.auth.signOut();
  }
}
