// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    baseUrl: 'http://localhost:3000',
  },
  supabase: {
    supabaseUrl: 'https://qxvkowczmqcexpkennco.supabase.co',
    supabaseKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF4dmtvd2N6bXFjZXhwa2VubmNvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTQyNTUwMzcsImV4cCI6MTk2OTgzMTAzN30.D7bUY7heCx7zrYYQnwAzeaHaDGQWEIgKdSdw0plmWhk',
  },
  algolia: {
    appId: 'WGU71GKK0L',
    apiKey: '2b4e3f55d86eac84c63a64e0c0b98b04',
  },
  cubejs: {
    // url: 'https://teal-leopon.aws-eu-central-1.cubecloudapp.dev/cubejs-api/v1',
    // token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTg4OTcwMDd9.OXW2ZWd6Rr3hbstrzgCct1r3oNStG0fxqlFWqmK1UyQ',
    url: 'https://jade-ladybug.aws-eu-central-1.cubecloudapp.dev/cubejs-api/v1',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDg4NzE4ODl9.joXPQZ-iXvwnJjl03sVF46pqPkyVCaXAdtnfB2W6gC0',
  },

  sentry: {
    dsn: 'https://e97e37c5a635323b7ba116c3b98d75c1@o4506229764259840.ingest.sentry.io/4506229765701632',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
